import { Link, NavLink } from "react-router-dom";
import { ROUTE__ADMISSIONS, ROUTE__CONTACT_US, ROUTE__HOME } from "../../constants";
import './index.css'

const HeaderNavigation = () => {
  return <div className="flex gap-4 link-container">
    <div className="link-div-wrapper">
      <NavLink className="link-div cursor-pointer text-[var(--clr-bg-darkest)] text-xl tracking-wider no-underline" to={ROUTE__HOME}>Home</NavLink>
    </div>
    <div className="separator"></div>
    <div className="link-div-wrapper">
      <NavLink className="link-div cursor-pointer text-[var(--clr-bg-darkest)] text-xl tracking-wider no-underline" to={ROUTE__ADMISSIONS}>Admissions</NavLink>
    </div>
    <div className="separator"></div>
    <div className="link-div-wrapper">
      <NavLink className="link-div cursor-pointer text-[var(--clr-bg-darkest)] text-xl tracking-wider no-underline" to={ROUTE__CONTACT_US}>Contact Us</NavLink>
    </div>
  </div>
}

export default HeaderNavigation;