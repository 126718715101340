import banner1 from '../../../assets/images/Banner/Banner1.webp'
import banner2 from '../../../assets/images/Banner/Banner2.jpeg'
import banner3 from '../../../assets/images/Banner/banner3.jpg'
import npcopBanner from '../../../assets/images/Banner/NPCOP_banner.jpeg'
import Looper from '../../../assets/images/Decorative/Looper.svg'
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import Carousel from 'react-multi-carousel';
import './index.css'

export const Banner = () => {


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <div className='banner w-full h-[400px] md:h-[400px] xl:h-[550px] '>
      <div className='bannder w-full h-[400px] md:h-[400px] xl:h-[550px] '>
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={true}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          // autoPlay={false}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          transitionDuration={500}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          itemClass="h-[400px] md:h-[400px] xl:h-[550px]"
        >
          <div className='max-w-[var(--page-container-max-w)] mx-auto'>
            <Zoom triggerOnce>
              <div className='h-[400px] xl:h-[550px] flex justify-center items-center'>
                <img
                  className="h-[250px] md:h-[400px] xl:h-[550px] bg-cover w-full"
                  src={npcopBanner}
                  alt={`imgss `}
                />
              </div>
            </Zoom>
          </div>
          <div className='max-w-[var(--page-container-max-w)] mx-auto'>
            <HomepageBanner />
          </div>
        </Carousel>
      </div>
    </div>
  );

}

const HomepageBanner = () => {
  return <div className='w-[98%] h-[100%] h-banner-grid mx-auto flex justify-center items-center'>
    <Zoom triggerOnce>
      <div className='z-[2] relative'>
        <img
          className="banner-img rounded-md mx-auto h-[400px] md:h-[400px] xl:h-[550px] w-auto object-contain"
          src={banner1}
          alt={`imgBanner1`}
        />
      </div>
    </Zoom>

    <Slide
      direction='right'
      delay={50}
      triggerOnce
    >
      <div className='banner-right rounded-tr-[2vw] rounded-br-[2vw]'>
        <div className='py-20 md:max-2xl:py-10 2xl:py-20  px-10'>
          <div className='md:max-2xl:text-[2.5rem] 2xl:text-[3.5rem] right-title tracking-widest text-white'>
            <Fade
              direction='up'
              delay={100}
              triggerOnce
            >
              <p>Hurry ! Limited seats left</p>
            </Fade>
          </div>
          <div className='md:text-[1.5rem] xl:text-[1.8rem] mt-7 tracking-wider text-white'>
            <Fade
              direction='up'
              delay={200}
              triggerOnce
            >
              Take this opportunity to boost your career in Physiotherapy

            </Fade>
          </div>
        </div>
        <img
          src={Looper}
          alt='Looper'
          className='deco-banner'
        />
      </div>
    </Slide>

  </div>
}
