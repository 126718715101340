import MotoImg from '../../../assets/images/Admissions/moto.jpg'
import './index.css'
import { Fade } from 'react-awesome-reveal';
import VisOnScroll from '../../../components/VisOnScroll';

const Moto = () => {
  return <div className="py-8 pb-5">
    <div className='md:mt-5'>
      <div className='moto-grid text-white lg:p-6'>
        <div className='moto-image'>
          <VisOnScroll>
            <img
              src={MotoImg}
              alt='college'
              className='img'
            />
          </VisOnScroll>
        </div>
        <Fade triggerOnce delay={1200} className='w-full lg:w-auto'>
          <div className='moto-para px-4 lg:px-0 w-[95vw] lg:w-auto text-[0.9rem] md:text-[1.5rem]'>
            At Narasu Patil college of Physiotherapy (NPCOP), we are committed to preparing the next generation of healthcare professionals through our comprehensive and dynamic Physiotherapy program. Our curriculum integrates theoretical knowledge with practical experience, ensuring that students are equipped with the skills necessary to excel in the field.
          </div>
        </Fade>
      </div>
    </div>
  </div>
}

export default Moto;