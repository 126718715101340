import VisOnScroll from "../VisOnScroll";
import Ribbon from '../../assets/images/Ribbons/BoardMembers.svg'
import ArcImg from '../../assets/images/Decorative/Arcs.svg'
// import ChancellorImg from '../../assets/images/Teachers/chancellor_photo.webp'
// import VChancellorImg from '../../assets/images/Teachers/vc_photo.webp'
// import ProChancellorImg from '../../assets/images/Teachers/mushrif.webp'
// import ProViceChancellorImg from '../../assets/images/Teachers/provc_photo.webp'
// import RegistrarImg from '../../assets/images/Teachers/registrar_photo.webp'
import PresidentImg from '../../assets/images/BoardMembers/President.jpeg'
import SecretaryImg from '../../assets/images/BoardMembers/Secretary.jpeg'
import './index.css'
import { Slide, Zoom } from "react-awesome-reveal";

const BoardMembers = () => {
  const json = [
    {
      img: PresidentImg,
      name: 'Shri Narsu patil',
      desig: 'President',
      subtitle: ''
    },
    {
      img: SecretaryImg,
      name: 'Mr. Bramhanand Patil',
      desig: 'Secretary',
      subtitle: ''
    },
  ]
  return <div className="board-members-wrapper mt-8 py-5 relative">
    {/* Meet the Mentors */}
    <VisOnScroll>
      <img
        src={Ribbon}
        alt="ribbon"
        className="mx-auto w-[65vw] md:w-[300px] lg:w-[22vw]"
        />
    </VisOnScroll>


    <div className="grid-wrapper">

      <Slide
        triggerOnce
        direction="up"
      >
        <div className="card-grid">
          {
            json.map((item, index) => {

              return <div className="card">
                <div className="board-member-img-wrap">
                  <img
                    src={item.img}
                    alt="ribbon"
                    className="board-member-img w-full h-full"
                  />
                </div>
                <div className="board-member-name text-[0.9rem] md:text-[1.2rem] md:py-[10px] px-[5px] sm:px-[10px]">{item.name}</div>
                <div className="info">
                  <div className="designation text-[0.9rem] md:text-[0.95rem]">{item.desig}</div>
                  <div className="subtitle">{item.subtitle}</div>
                </div>
              </div>

            })
          }
        </div>
      </Slide>
    </div>
  </div>
}

export default BoardMembers;