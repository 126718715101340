import { FaClock, FaEnvelope, FaPhone } from 'react-icons/fa6';
import './index.css'
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Fade } from 'react-awesome-reveal';
import Logo from '../../assets/images/logo_npcop.svg'

const Footer = () => {

  return <div className="w-full f-wrapper z-[222]" id="footer">
    <div className="max-w-[var(--page-container-max-w)] mx-auto px-5">
      <div className="footer-grid py-8">

        <Fade
          delay={200}
          triggerOnce
          className='grid-area-1'
        >
          <div className="footer-col">
            <img
              src={Logo}
              alt="logo"
              className='h-[89px] lg:h-[150px] mix-blend-lighten'
            />
          </div>
        </Fade>

        <Fade
          triggerOnce
          className='grid-area-2'

        >
          <div className="footer-col">
            <div className="f-title text-xl sm:text-3xl  mb-3 text-center contact">Contact</div>
            <div className='flex flex-col sm:flex-row sm:gap-6'>
              <div className="f-row">
                <div>
                  <FaMapMarkerAlt className='f-icon' size={15} />
                </div>
                <div className='text-sm sm:text-md leading-[1.5] address'>
                  <a href='https://maps.app.goo.gl/j92PyCtkDTkJWgRPA?g_st=iw'>
                    Plot No. P-19, Badyachiwadi MIDC Area, Adjacent to Shendri Lake, Near Kalbhairi Temple,A/p.-Badyachiwadi, Tal.Gadhinglaj, Dist. Kolhapur. State: Maharashtra, India, PIN-416502.
                  </a>
                </div>
              </div>
              <div className=''>
                <div className="f-row">
                  <div>
                    <FaPhone className='f-icon' size={15} />
                  </div>
                  <div className='text-sm sm:text-md leading-[1.5]'>
                    <div className='flex items-center gap-1 sm:block'>
                      <a className='block' href='tel:+919702804777'>
                        +91 97028 04777
                      </a>
                      <div className='text-white sm:hidden'>{'/'}</div>
                      <a className='block' href='tel:+917977936476'>
                        +91 79779 36476
                      </a>
                    </div>
                  </div>
                </div>
                <div className="f-row">
                  <div>
                    <FaEnvelope className='f-icon' size={15} />
                  </div>
                  <div className='text-sm sm:text-md leading-[1.5]'>
                    <a href='mailTo:npcophysio@gmail.com'>
                      npcophysio@gmail.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>

        <Fade
          delay={200}
          className='grid-area-3'
          triggerOnce
        >
          <div className="footer-col">
            <div className="f-title timings text-xl sm:text-3xl  mb-3">Timings</div>
            <div className="f-row">
              <div>
                <FaClock className='f-icon' size={15} />
              </div>
              <div className='text-sm sm:text-md leading-[1.5]'>
                <div>Monday - Friday</div>
                <div>8.00 AM - 6.00 PM</div>
              </div>
            </div>
          </div>
        </Fade>
      </div>


      <div className='rights-line flex flex-col sm:flex-row sm:gap-6 leading-7 sm:text-sm items-center justify-center'>
        <div>Narasu Patil college of Physiotherapy (NPCOP)</div>
        <div>All rights reserved by Flint Infotech 2024</div>
      </div>
    </div>
  </div>
}

export default Footer;