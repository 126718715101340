import { configureStore } from '@reduxjs/toolkit'
import commonReducer from '../src/slices/commonSlice'

export const store = configureStore({
  reducer: {
    all: commonReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch