import './index.css'
import Bg from '../../assets/images/ContactUs/cu-bg.jpg'
import Ribbon from '../../assets/images/Ribbons/ContactUs.svg'
import loops from '../../assets/images/ContactUs/Loops.svg'
import { Fade } from 'react-awesome-reveal';
import Form from './Form';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { mobileQuery } from '../../helpers';

const ContactUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <div className="contact-wrapper" >
    <div className='cu-content'>
      <div className='cu-left-image-box '>
        {/* <img
          src={Bg}
          alt=""
          className="h-full object-cover"
        /> */}
      </div>

      <div className='cu-right-wrapper'>

        <div className='sm:max-w-[50ch] mx-auto cu-form-section'>
          <Fade triggerOnce className='cu-ribbon'>
            <img
              src={Ribbon}
              alt="ribbon"
              className="mx-auto mt-6 w-[65vw] md:w-[300px] lg:w-[22vw]"
              />
          </Fade>

          <Fade triggerOnce>
            <div className='text-center text-black font-semibold text-[0.9rem] sm:text-md py-4'>
              For any assistance, fill out the form below, and we’ll get back to you as soon as possible
            </div>
          </Fade>

          <div className='form-section max-w-[90%] mx-auto relative'>
            <Fade triggerOnce delay={500}>
              <Form />
            </Fade>
            <img
              src={loops}
              alt="loops"
              className="absolute right-[-30%] bottom-0 w-[100%] h-[100%] z-[-3] opacity-45"
            />
          </div>

        </div>
      </div>
    </div>
  </div>
}

export default ContactUs;