import Ribbon from '../../assets/images/Ribbons/WhoWeAre.svg'
import VisOnScroll from "../VisOnScroll";
import College from '../../assets/images/Banner/Banner2.jpeg'
import './index.css'
import { Fade } from 'react-awesome-reveal';
import Timeline from './Timeline';

const WhoWeAre = () => {
  return <div className="who-we-are-wrapper pb-5">
    <img
      src={Ribbon}
      alt="ribbon"
      className="mx-auto w-[65vw] md:w-[300px] lg:w-[22vw]"
    />
    <div className='mt-2 md:mt-5'>
      <div className='about-grid text-white p-6'>
        <Fade triggerOnce>
          <div className='about-para text-[3.7vw] sm:max-md:text-[0.8rem] md:max-lg:text-[1.15rem] lg:text-[1.4vw]'>
            Disha Shikshan –V- Vikas Kendra’s, Narsu Patil  College of physiotherapy is located in the MIDC Area of Gadhinglaj City a sub district place in the State of Maharashtra of Indian nation. The college conducts the BPTH Degree course in Physiotherapy  , Government of India with effect from the academic year, 2024 – 2025 .
          </div>
        </Fade>
        <div className='img'>
          <VisOnScroll>
            <img
              src={College}
              alt='college'
              className='about-image'
            />
          </VisOnScroll>
        </div>
      </div>
    </div>

    <div className='timeline'>
      <Timeline />
    </div>
  </div>
}

export default WhoWeAre;