import VisOnScroll from '../../components/VisOnScroll';
import './index.css'
import Ribbon from '../../assets/images/Ribbons/LearningWithUs.svg'
import Banner from '../../assets/images/Admissions/banner2.jpg'
import { Fade, Slide, Zoom } from 'react-awesome-reveal';
import { useEffect } from 'react';
import Moto from './Moto';
import Looper from '../../assets/images/Decorative/Looper.svg'
import banner1 from '../../assets/images/Banner/Banner1.webp'

const Admissions = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const whyJson = [
    {
      title: 'Accredited Curriculum',
      desc: 'Our program is guided by Central Council of Physiotherapy, ensuring that you receive a high-quality education recognized by employers and professional organizations.'
    },
    {
      title: 'Expert Faculty',
      desc: 'Learn from experienced faculty members who are leaders in the field of physiotherapy and dedicated to student success.'
    },
    {
      title: 'State-of-the-Art Facilities',
      desc: 'Gain hands-on experience in our modern labs and clinics, equipped with the latest technology and resources.'
    },
    {
      title: 'Clinical Opportunities',
      desc: 'Benefit from clinical placements in diverse healthcare settings, allowing you to apply your learning in real-world environments.'
    },
  ]

  const admsnListJson = [
    {
      title: 'NEET Admit Card',
    },
    {
      title: 'NEET Marksheet',
    },
    {
      title: 'Leaving Certificate',
    },
    {
      title: 'S.C. Passing Certificate',
    },
    {
      title: 'Aadhar Card',
    },
    {
      title: 'PAN Card',
    },
    {
      title: 'Permanent Address Proof',
    },
    {
      title: 'Parent’s Income Certificate / Form 16 (If applicable)',
    },
    {
      title: 'Age Domicile Certificate',
    },
    {
      title: 'Cast Certificate (If applicable)',
    },
    {
      title: 'Cast Certificate (If applicable)',
    },
    {
      title: 'Non Creamy Layer Certificate (If applicable)',
    },
    {
      title: 'Passport Size Photo in JPG format',
    },
  ]

  const downloadsJson = [
    {
      name: 'Notice Download',
      link: 'https://ebghmc.org/downloads/npcop/notice'
    },
    {
      name: 'HOD Notice',
      link: 'https://ebghmc.org/downloads/npcop/notice'
    },
    {
      name: 'Grant',
      link: `${window.location.origin}/documents/Grant.pdf`,
      static: true
    },
    {
      name: 'Affiliation by Maharashtra Univerisity of Health Sciences, Nashik',
      link: `${window.location.origin}/documents/Affiliation.pdf`,
      static: true
    },
  ]

  return <div className='admission-wrapper'>

    <Fade triggerOnce>
      <img
        src={Banner}
        alt="ribbon"
        className="w-full h-[400px] md:h-[400px] xl:h-[550px] banner-image"
      />
    </Fade>
    <img
      src={Ribbon}
      alt="ribbon"
      className="mx-auto mt-6 w-[65vw] md:w-[300px] lg:w-[22vw]"
    />

    <div className='w-[calc(0.96*var(--page-container-max-w))] mx-auto'>

      {/* <div className='init-line my-9'>
        At Narasu Patil college of Physiotherapy (NPCOP), we are committed to preparing the next generation of healthcare professionals through our comprehensive and dynamic Physiotherapy program. Our curriculum integrates theoretical knowledge with practical experience, ensuring that students are equipped with the skills necessary to excel in the field.
      </div> */}
      <Moto />

      <div className='admsn-page-grid'>

        <div className='left-section'>
          <section className='py-8'>
            <VisOnScroll>
              <Slide
                triggerOnce
              >
                <div className='title text-[1.5rem] md:text-[2.7rem] '>
                  <div className='w-fit content'>Why Choose us?</div>
                </div>
              </Slide>
            </VisOnScroll>

            <div className='why-us-content py-4 sm:py-8 ml-4 sm:ml-0'>
              {
                whyJson.map((item, index) => {

                  return <div className='line-wrap'>
                    <VisOnScroll key={`${index}`}>
                      <Fade
                        triggerOnce
                        delay={index * 150}
                      >
                        <div >
                          <div className='line'>
                            {item.title}
                          </div>
                          <div className='subtitle'>
                            {item.desc}
                          </div>
                        </div>
                      </Fade>
                    </VisOnScroll>
                  </div>
                })
              }
            </div>
          </section>

          <section>
            <VisOnScroll>
              <Slide
                triggerOnce
              >
                <div className='title text-[1.5rem] md:text-[2.7rem] '>
                  <div className='w-fit content'>Ongoing Admission</div>
                </div>
              </Slide>
            </VisOnScroll>

            <table className='text-md md:text-2xl my-6'>
              <tr>
                <td className='text-rights max-w-[15chss]'>Course</td>
                <td className='px-3'>:</td>
                <td className='font-bold' style={{ verticalAlign: 'top' }}>UG Degree Course BPTH</td>
              </tr>
              <tr>
                <td className='text-rights max-w-[15chss]'>Intake</td>
                <td className='px-3'>:</td>
                <td className='font-bold' style={{ verticalAlign: 'top' }}>50</td>
              </tr>
              <tr>
                <td className='text-rights max-w-[15chss]'>College code</td>
                <td className='px-3'>:</td>
                <td className='font-bold' style={{ verticalAlign: 'top' }}>06406</td>
              </tr>
            </table>

            {/* <div className='pt-4 sm:pt-9 text-center text-lg md:text-2xl'>
              Course: <span className='font-bold'>UG Degree Course BPTH</span>
            </div>
            <div className='pt-4 text-center text-md md:text-xl'>
              Intake: <span className='font-bold'>50</span>
            </div>
            <div className='pt-4 text-center text-md md:text-xl'>
              College code for admission: <span className='font-bold'>06406</span>
            </div> */}

            <div className='ongoing-admsn-grid'>

              <div className='oa-area-1'>
                <div className='pt-4 sm:pt-9 font-ddd text-lg'>
                  Selected candidates should confirm their admission by depositing requisite Fees along with all Original Documents + 02 Attested Xerox Sets + Photograph:
                </div>

                <div className='ongoing-adm-content py-4 sm:py-8 ml-4 sm:ml-0'>
                  <ul>
                    {
                      admsnListJson.map((item, index) => {

                        return <li className='line-wrap'>
                          <VisOnScroll key={`${index}`}>
                            <Fade
                              triggerOnce
                              delay={index * 150}
                            >
                              <div className='line'>
                                {item.title}
                              </div>
                            </Fade>
                          </VisOnScroll>
                        </li>
                      })
                    }
                  </ul>
                </div>
              </div>

              <div className='admsn-img-box oa-area-2'>
                <Slide direction='up'>
                <img
                  className=""
                  src={banner1}
                  alt={`img banner1`}
                />
                </Slide>
              </div>

            </div>
          </section>
        </div>

        <div className='right-section bg-white'>
          <div className='title'>Downloads</div>

          <div className='downloads-content py-8'>
            {
              downloadsJson.map((item, index) => {

                return <div className='line-wrap'>
                  <VisOnScroll key={`${index}`}>
                    <Fade
                      triggerOnce
                      delay={index * 150}
                    >
                      <div >
                        {
                          item.static ?
                            // <div onClick={e => handleDocDownload(item.link)}>
                            //   {item.name}
                            // </div> :
                            <a className='line' href={item.link} target='_blank' download={item.name}>
                              {item.name}
                            </a> : 
                            <a className='line' href={item.link} target='_blank'>
                              {item.name}
                            </a>
                        }
                      </div>
                    </Fade>
                  </VisOnScroll>
                </div>
              })
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}


const AdmsnPageBanner = () => {
  return <div className='w-fit mx-auto flex justify-center items-center'>
    <Zoom triggerOnce>
      <div className='z-[2] relative'>
        <img
          className="banner-img rounded-md d-bldock w-auto mx-auto h-[550px] object-contain"
          src={banner1}
          alt={`img banner1`}
        />
      </div>
    </Zoom>

    <Slide
      direction='right'
      delay={50}
      triggerOnce
    >
      <div className='banner-right rounded-tr-[5vw] rounded-br-[5vw]'>
        <div className='py-20 px-10'>
          <div className='text-[4rem] right-title tracking-widest text-white'>
            {/* <Typewriter text="Hurry ! Limited seats left" speed={100} /> */}
            <Fade
              direction='up'
              delay={100}
              triggerOnce
            >
              <p>Hurry ! Limited seats left</p>
            </Fade>
          </div>
          <div className='text-[2rem] mt-7 tracking-wider text-white'>
            <Fade
              direction='up'
              delay={200}
              triggerOnce
            >
              Take this opportunity to boost your career in Physiotherapy

            </Fade>
          </div>
        </div>
        <img
          src={Looper}
          alt='Looper'
          className='deco-banner'
        />
      </div>
    </Slide>

  </div>
}


export default Admissions;