import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import MarqueeSlider from "./MarqueeSlider";
import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { setReduxState } from "../slices/commonSlice";
import { snackInitialState } from "../helpers";
import { useEffect, useState } from "react";


const AppLayout = () => {
  const { snackState } = useSelector((state: RootState) => state.all);
  const dispatch = useDispatch()
  // console.log('hex: ', snackState)
  const [footerHeight, setFooterHeight] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      const footerElement = document.getElementById('footer');
    if (footerElement) {
      setFooterHeight(footerElement.getBoundingClientRect().height);
    }
    }, 1000);
  }, [])


  useEffect(() => {

    if (snackState.open) {
      setTimeout(() => {
        dispatch(setReduxState({
          name: 'snackState',
          value: { ...snackInitialState }
        }))
      }, 6000);
    }
  }, [snackState])

  return <div className="relative">
    <Snackbar
      open={snackState.open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={6000}
      onClose={(e) => {
        dispatch(setReduxState({
          name: 'snackState',
          value: { ...snackInitialState }
        }))
      }}
      sx={{
        zIndex: 'calc(var(--top-z-index) + 1)'
      }}
    >
      <Alert
        onClose={(e) => {
          dispatch(setReduxState({
            name: 'snackState',
            value: { ...snackInitialState }
          }))
        }}
        severity={snackState.severity}
        variant="filled"
        sx={{
          width: '100%',
          zIndex: 'calc(var(--top-z-index) + 1)'
        }}
      >
        {snackState.message}
      </Alert>
    </Snackbar>
    <Header />
    <MarqueeSlider>
      Admissions for 2024 batch have begun. Please contact our website to avail admission discounts !
    </MarqueeSlider>
    {/* <div className="max-w-[var(--page-container-max-w)] mx-auto px-5 overflow-x-hidden"> */}
    <div className="overflow-x-clip" style={{
      minHeight: `calc(90vh - ${footerHeight}px)`
    }}>
      <Outlet />
    </div>
    <Footer />
  </div>
}

export default AppLayout;