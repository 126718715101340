// import Logo from '../../assets/images/logo.svg'
// import Logo from '../../assets/images/logo2.jpeg'
import Logo from '../../assets/images/logo_npcop.svg'
import HeaderNavigation from '../Navigation/HeaderNavigation';
import Marquee from "react-fast-marquee";
import './index.css'
import MarqueeSlider from '../MarqueeSlider';
import { NavLink } from 'react-router-dom';
import { ROUTE__HOME } from '../../constants';
import { useMediaQuery } from "react-responsive"
import { mobileQuery } from '../../helpers';
import Hamburger from '../Hambuger';

const Header = () => {
  const isMobile = useMediaQuery(mobileQuery)

  return <div className="sticky top-0  header-wrapper z-[var(--top-z-index)]">
    <div className='bg'></div>
    <div className="max-w-[var(--page-container-max-w)] mx-auto px-2 sm:px-5 py-1 md:py-2 flex justify-between items-center">
      <div className="logo">
        <NavLink to={ROUTE__HOME}>
          <img
            src={Logo}
            alt="logo"
            className='h-[40px] md:h-[50px] scale-[140%]'
          />
        </NavLink>
      </div>
      <div className="nav">
        {
          !isMobile &&
          <HeaderNavigation />
        }
        {
          isMobile && <Hamburger />
        }
      </div>
    </div>

  </div>
}

export default Header;