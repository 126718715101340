import { useEffect, useRef, useState } from 'react';
import './index.css'
import Hamburger from 'hamburger-react'
import { Box, Menu, MenuItem } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ROUTE__ADMISSIONS, ROUTE__CONTACT_US, ROUTE__HOME } from '../../constants';

const HamburgerC = () => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {

  },)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return <Box
  >
    <div
      className='w-auto h-auto overflow-hidden mx-auto my-auto'
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={open ? handleClose : handleClick}
    >
      <Hamburger
        size={22}
        toggled={open}
        color='var(--clr-bg-darkest)'
      />
    </div>

    <Menu
      anchorEl={anchorEl}
      id="basic-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      sx={{
        zIndex: 'calc(var(--top-z-index) + 1)',
        fontFamily: '"Cabin", sans-serif',
        fontSize: '0.8rem'
      }}
      slotProps={{

        paper: {
          elevation: 3000,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 2000,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={handleClose}
        sx={{
          fontFamily: '"Cabin", sans-serif',
          minHeight: 'auto'
        }}
      >
        <NavLink className="link-div cursor-pointer text-[var(--clr-bg-darkest)] text-md  no-underline" to={ROUTE__HOME}>Home</NavLink>
      </MenuItem>
      <MenuItem onClick={handleClose}
        sx={{
          fontFamily: '"Cabin", sans-serif',
          minHeight: 'auto'
        }}
      >
        <NavLink className="link-div cursor-pointer text-[var(--clr-bg-darkest)] text-md  no-underline" to={ROUTE__ADMISSIONS}>Admissions</NavLink>
      </MenuItem>
      <MenuItem onClick={handleClose}
        sx={{
          fontFamily: '"Cabin", sans-serif',
          minHeight: 'auto'
        }}
      >
        <NavLink className="link-div cursor-pointer text-[var(--clr-bg-darkest)] text-md no-underline" to={ROUTE__CONTACT_US}>Contact Us</NavLink>
      </MenuItem>
    </Menu>
  </Box>
}

export default HamburgerC;