import React from "react";
import Marquee from "react-fast-marquee";
import './index.css'
import { Fade } from "react-awesome-reveal";
const MarqueeSlider = ({
  children
}: {
  children: React.ReactNode
}) => {
  return <Fade className="sticky top-[10px]" triggerOnce delay={1000}>
    <div className="styled-marquee py-1">
      <Marquee speed={70}>
        {children}
      </Marquee>
    </div>
  </Fade>
}

export default MarqueeSlider;