import { useEffect } from "react";
import KeyHighlights from "../../components/KeyHighlights";
import Teachers from "../../components/BoardMembers";
import WhoWeAre from "../../components/WhoWeAre";
import { Banner } from "./Banner";


const Homepage = () => {
  
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return <div className="sh-[120vh]">
    <Banner />
    <div className="mt-8">
        <WhoWeAre />
        <KeyHighlights />
        <Teachers />
    </div>
  </div>
}
 
export default Homepage;