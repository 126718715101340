import VisOnScroll from '../VisOnScroll';
import './index.css'
import Ribbon from '../../assets/images/Ribbons/KeyHighlights.svg'
import ArcImg from '../../assets/images/Decorative/Looper2Blue.svg'
import { Fade } from 'react-awesome-reveal';

const KeyHighlights = () => {

  const json = [
    {
      node: <>The college has enriched library with 7892 textbooks and reference books on various subjects</>
    },
    {
      node: <>The average percentage of BPTH passing in the college is above 80%</>
    },
    {
      node: <>This college is also doing excellent in university level sports, extracurricular and co-curricular activities as well</>
    },
    {
      node: <>Our students are promised to excel their career in providing best treatment as Physiotherapists</>
    },
  ]

  return <div className="keyh-wrapper max-w-[var(--page-container-max-w)] mx-auto px-5 py-1 md:py-5 my-2 md:my-10">
    <div className='keyh-box px-6 py-4 sm:px-9 sm:py-6'>
      <img
        src={ArcImg}
        alt="ribbon"
        className="arcs w-full h-full"
      />
      <VisOnScroll>
        <img
          src={Ribbon}
          alt="ribbon"
          className="mx-auto w-[65vw] md:w-[300px] lg:w-[22vw]"
        />
      </VisOnScroll>
      <div className='keyh-content pt-4 sm:pt-8'>
        {
          json.map((item, index) => {

            return <VisOnScroll key={`${index}`}>
              <Fade
                triggerOnce
                delay={index * 150}
              >
                <div className='line text-[0.8rem] md:text-[1.5vw]'>
                  {item.node}
                </div>
              </Fade>
            </VisOnScroll>
          })
        }
      </div>
    </div>
  </div>
}

export default KeyHighlights;