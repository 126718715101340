
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaBookReader, FaPercent } from "react-icons/fa";
import { FaLightbulb, FaAward } from "react-icons/fa6";
import './index.css'

const Timeline = () => {
  return <div className='timeline-wrapper mt-3 md:mt-5'>
    <VerticalTimeline>
      <VerticalTimelineElement
        className="timeline-element vertical-timeline-element--work"
        iconStyle={{ background: '#f3d921', color: '#fff' }}
        icon={<FaLightbulb />}
      >
        <h3 className="timeline-element-title text-[0.8rem] sm:text-[1rem] lg:text-[1.3vw]">Inaugurated on 16<sup>th</sup> Septemeber 1991 at Gadhinglaj</h3>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="timeline-element vertical-timeline-element--work"
        iconStyle={{ background: '#00b03e', color: '#fff' }}
        icon={<FaAward />}
      >
        <h3 className="timeline-element-title text-[0.8rem] sm:text-[1rem] lg:text-[1.3vw]">Recognized by the <b>Central Council of Physiotherapy, Ministry of Health and family welfare, Government of India</b></h3>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="timeline-element vertical-timeline-element--work"
        iconStyle={{ background: '#b06600', color: '#fff' }}
        icon={<FaPercent />}
      >
        <h3 className="timeline-element-title text-[0.8rem] sm:text-[1rem] lg:text-[1.3vw]">Average passing percentage: <b>80%</b></h3>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="timeline-element vertical-timeline-element--work"
        iconStyle={{ background: '#b00000', color: '#fff' }}
        icon={<FaBookReader />}
      >
        <h3 className="timeline-element-title text-[0.8rem] sm:text-[1rem] lg:text-[1.3vw]">Highly qualified teaching facility, expertized in Medicine, Rehabilitation, Manual Therapy, Exercise Therapy, Neurological Rehabilitation, Sports Physiotherapy, Pediatric Physiotherapy, Pain Management etc.</h3>
      </VerticalTimelineElement>
    </VerticalTimeline>
  </div>
}

export default Timeline;