import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-multi-carousel/lib/styles.css';
import AppLayout from './components/AppLayout';
import Homepage from './pages/Homepage';
import Admissions from './pages/Admissions';
import { ROUTE__ADMISSIONS, ROUTE__CONTACT_US } from './constants';
import ContactUs from './pages/ContactUs';
import { Provider } from 'react-redux';
import { store } from './store';

function App() {

  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: (
        <AppLayout />
      ),
      children: [
        {
          index: true,
          element: <Homepage />,
        },
        {
          path: ROUTE__ADMISSIONS,
          element: <Admissions />,
        },
        {
          path: ROUTE__CONTACT_US,
          element: <ContactUs />,
        }
      ]
    }
  ])
  return (
    <div className="App">
      <Provider store={store}>
        <RouterProvider router={appRouter} />
      </Provider>
    </div>
  );
}

export default App;
